import * as Types from '../store/types';
import * as GT from '../tools/general-tools';
import * as actions from '../store/constants/all';
import * as Actions from '../store/actions/general';
import { routes as Routes } from '../store/constants/routes';
import { EventEmitter } from 'events';
import Translator from './translate-factory';
import PerculusApiClient from './perculus-api-client';
import * as Enums from '../store/constants/enums';

import { Log } from 'ng2-logger';
import MockAPIClient from './mock-api-client';
//const L = Log.create('PerculusFactory');
const T = Translator.create();

let _instance: AppFactory;
export default class AppFactory extends EventEmitter {
  dispatcher: any;
  private _state: Types.IAppState = GT.GetInitialState();
  private _modules: any = {};
  private _apapi: MockAPIClient = new MockAPIClient();

  constructor(useSingleton: boolean = true) {
    super();
    if (_instance && useSingleton) {
      return _instance;
    } else {
      this.init();
      _instance = this;
    }
  }

  static create(): AppFactory {
    return new AppFactory();
  }

  init() {
    this._modules[actions.gen.CORE_TRANSLATOR_READY] = false;
    this._modules[actions.gen.CORE_PERSISTOR_READY] = false;
    this._modules[actions.gen.CORE_WELL_KNOWN_UNITS] = true;
    // this._modules[actions.gen.CORE_USER_PERMISSIONS] = false;

    this._apapi = new MockAPIClient();
    this._apapi._appFactory = this;
    T.on(actions.gen.CORE_TRANSLATOR_READY, () => {
      this.setAppModuleReady(actions.gen.CORE_TRANSLATOR_READY);
    });
  }

  inRole(roles: Array<string>) {
    return roles.includes(this._state.user.role);
  }

  setAppModuleReady = (key: string, value: boolean = true) => {
    this._modules[key] = value;
    if (this.hasAppReady() && this.dispatcher) {
      setTimeout(() => {
        this.dispatcher(Actions.AppReady());
      }, 0);
    }
  };

  hasAppReady = (): boolean => {
    const ready: boolean = Object.keys(this._modules).filter(k => this._modules[k] === false).length === 0;
    return ready;
  };

  setAcceptableState = (state: Types.IAppState) => {
    this._state = state;
    if (!!state.auth) {
      localStorage.setItem("access_token", state.auth.access_token);
    }
    if (this.hasLoggedOn()) {
      this._apapi._accessToken = this._state.auth.access_token;
      this._apapi._termId = this._state.term_id;
    } else {
      this._apapi._accessToken = '';
    }
  };

  setStateFunctions = (state: Types.IAppState): Types.IAppState => {
    if (state.user) {
      state.user.hasPermission = function hasPermission(this: Types.IAuthUser, type: Enums.PermissionType): boolean {
        return (type === 0 || (this.permissions && this.permissions.indexOf(type) > -1)) as boolean;
      };
      state.user.hasPermissions = function hasPermission(this: Types.IAuthUser, types: Array<Enums.PermissionType>): boolean {
        if (types && types.length > 0 && this.hasPermission) {
          let ok: boolean = true;
          types.forEach(t => {
            if (this.hasPermission && !this.hasPermission(t)) {
              ok = false;
            }
          });
          return ok;
        } else {
          return true;
        }
      };
    }
    return state;
  };

  hasLoggedOn = (): boolean => {
    if (this._state.auth && this._state.auth.access_token) {
      return true;
    }
    return false;
  };

  hasPermission = (type: Enums.PermissionType): boolean => {
    if (this._state.user && this._state.user.hasPermission) {
      return this._state.user.hasPermission(type);
    } else {
      return true;
    }
  };

  hasPermissions = (types: Array<Enums.PermissionType>): boolean => {
    if (this._state.user && this._state.user.hasPermissions) {
      return this._state.user.hasPermissions(types);
    } else {
      return true;
    }
  };

  logOff = (silent: boolean = false) => {
    const fnClear = (silent: boolean) => {
      if (!silent) {
        this.dispatcher(Actions.Notification('notification_signed_out', 'gen_warning'));
      }
      this.resetState();
      this.dispatcher(Actions.Navigation(GT.Route(Routes.LOGIN), 0));
    };

    if (this.hasAppReady() && this.dispatcher) {
      fnClear(silent);
    } else {
      this.on(actions.gen.CORE_APP_READY, () => {
        if (this.dispatcher) {
          fnClear(silent);
        }
      });
    }
  };

  resetState = () => {
    localStorage.removeItem('persist:primary');
    this._apapi._accessToken = '';
    if (this.dispatcher) {
      this.dispatcher(Actions.ReloadState());
    }
  };

  emitting(action: Types.IAction): void {
    setTimeout(async () => {
      this.emit(action.type, action);
    }, 0);
  }

  broadcast(action: Types.IAction): void {
    this.emit(action.type, action);
  }
}

import { put, takeEvery, all, take, select, call, actionChannel } from 'redux-saga/effects';
import * as Actions from '../actions/general';
import { constants, ExamPeriodSearchInitialValues } from '../constants/exam-period-const';
import * as Types from '../types';
import MockAPIClient from '../../services/mock-api-client';
import { Log } from 'ng2-logger';

const Logger = Log.create('CopyCourseModal');
//#region Exam Periods Page

function* getExamPeriods(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.ExamPeriodSearch(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getCourseChangeAllData(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.CourseChangeAllData(action.body));
  let content = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_all_data_for_courses_changed', 'gen_success'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getExamPeriodList(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.GetTermList());
  let content: any = null;

  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getExamPeriodData(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.ExamPeriodGetById(action.body);
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* updateExamPeriod(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.ExamPeriodUpdate(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_exam_term_updated', 'gen_success'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* copyExamPeriod(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.ExamPeriodCopy(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('Kopyalama Başladı...', 'gen_success', 'sucess'));
  } else if (response && response.status === 409) {
    yield put(Actions.Notification('Dönem kopyalama işlemi var ve devam ediyor!', 'gen_warning', 'danger'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* copyExamPeriodGetRequestStatus(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.ExamPeriodCopyGetRequestStatus(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* createExamPeriod(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.ExamPeriodCreate(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_exam_term_created', 'gen_success'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* deleteExamPeriod(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.ExamPeriodDelete(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_exam_term_deleted', 'gen_success'));
    yield put(Actions.ApiRequest(constants.EXAM_PERIOD_LIST_SEARCH, ExamPeriodSearchInitialValues));
    content = yield MockAPI.GetContent(response);
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

export function* watchExamPeriodSearch() {
  yield takeEvery((action: any) => action.target === constants.EXAM_PERIOD_LIST_SEARCH, getExamPeriods);
}

export function* watchExamPeriodCourseChangeAllData() {
  yield takeEvery((action: any) => action.target === constants.EXAM_PERIOD_COURSE_CHANGE_ALL_DATA, getCourseChangeAllData);
}

export function* watchExamPeriodGetById() {
  yield takeEvery((action: any) => action.target === constants.EXAM_PERIOD_GET_BY_ID, getExamPeriodData);
}

export function* watchExamPeriodUpdate() {
  yield takeEvery((action: any) => action.target === constants.EXAM_PERIOD_UPDATE, updateExamPeriod);
}

export function* watchExamPeriodCopy() {
  yield takeEvery((action: any) => action.target === constants.EXAM_PERIOD_COPY, copyExamPeriod);
}

export function* watchExamPeriodCopyGetRequestStatus() {
  yield takeEvery((action: any) => action.target === constants.EXAM_PERIOD_COPY_REQUEST_STATUS, copyExamPeriodGetRequestStatus);
}

export function* watchExamPeriodCreate() {
  yield takeEvery((action: any) => action.target === constants.EXAM_PERIOD_CREATE, createExamPeriod);
}
export function* watchExamPeriodDelete() {
  yield takeEvery((action: any) => action.target === constants.EXAM_PERIOD_DELETE, deleteExamPeriod);
}

//#endregion

//#region Exam Dates Tab

function* createExamDates(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.ExamDatesCreate(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_exam_calendar_saved', 'gen_success'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* createCourseDates(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.ExamDatesCreate(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_course_calendar_saved', 'gen_success'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getExamDatesById(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.ExamDatesGetById(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    } else if (response && response.status === 404) {
      yield put(Actions.Notification('notification_term_could_not_be_found_select_term', 'gen_warning', 'danger'));
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* saveScheduletoStore(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = {};
  } else {
    response = yield {};
    content = yield action.body;
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* updateExamDates(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.ExamDatesUpdate(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_exam_calendar_saved', 'gen_success'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* updateCourseDates(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.ExamDatesUpdate(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_course_calendar_saved', 'gen_success'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* deleteExamDates(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.ExamDatesDelete(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_exam_calendar_deleted', 'gen_success'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* deleteCourseDates(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.ExamDatesDelete(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_course_calendar_deleted', 'gen_success'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

export function* watchExamDatesCreate() {
  yield takeEvery((action: any) => action.target === constants.EXAM_PERIOD_CREATE_EXAM_DATES, createExamDates);
}

export function* watchCourseDatesCreate() {
  yield takeEvery((action: any) => action.target === constants.EXAM_PERIOD_CREATE_COURSE_DATES, createCourseDates);
}

export function* watchExamDatesGetById() {
  yield takeEvery((action: any) => action.target === constants.EXAM_PERIOD_GET_PERIOD_DATES, getExamDatesById);
}

export function* watchExamDatesUpdate() {
  yield takeEvery((action: any) => action.target === constants.EXAM_PERIOD_UPDATE_EXAM_DATES, updateExamDates);
}

export function* watchCourseDatesUpdate() {
  yield takeEvery((action: any) => action.target === constants.EXAM_PERIOD_UPDATE_COURSE_DATES, updateCourseDates);
}

export function* watchExamDatesDelete() {
  yield takeEvery((action: any) => action.target === constants.EXAM_PERIOD_DELETE_EXAM_DATES, deleteExamDates);
}

export function* watchCourseDatesDelete() {
  yield takeEvery((action: any) => action.target === constants.EXAM_PERIOD_DELETE_COURSE_DATES, deleteCourseDates);
}

export function* watchSaveScheduletoStore() {
  yield takeEvery((action: any) => action.target === constants.EXAM_PERIOD_SAVE_SCHEDULE, saveScheduletoStore);
}

//#endregion

//#region term week date tab

function* getTermWeekDates(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.getTermWeekDates(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  if (action.callback && content) {
    action.callback(content);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* putTermWeekDates(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.putTermWeekDates(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  if (action.callback && content) {
    action.callback(content);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* deleteTermWeekExclutionDate(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.deleteTermWeekExclutionDate(action.body));
    if (response && response.status === 200) {
      yield put(Actions.Notification('notification_selected_term_week_exclution_deleted', 'gen_success'));
      if (action.callback) {
        action.callback(response, response.status);
      }
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* deleteTermWeekHolidayDate(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.deleteTermWeekHolidayDate(action.body));
    if (response && response.status === 200) {
      yield put(Actions.Notification('notification_selected_term_week_holiday_deleted', 'gen_success'));
      if (action.callback) {
        action.callback(response, response.status);
      }
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

//#endregion

//#region Instructor Hours Tab

function* getInstructorHoursById(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.InstructorHoursGetById(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* saveInstructorHours(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.InstructorHoursUpdate(action.body));
    if (response && response.status === 200) {
      yield put(Actions.Notification('notification_hours_saved', 'gen_success'));
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}
function* saveInstructorHoursMultiple(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.InstructorHoursUpdateMultiple(action.body));
    if (response && response.status === 200) {
      yield put(Actions.Notification('notification_hours_saved', 'gen_success'));
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* saveInstructorInvigilations(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.SaveInstructorInvigilations(action.body));
    if (response && response.status === 200) {
      yield put(Actions.Notification('notification_invigs_assigned_instructors_saved', 'gen_success'));
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* addInstructorsToPeriod(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.InstructorsAddToPeriod(action.body));
    if (response && response.status === 200) {
      yield put(Actions.Notification('notification_selected_instructors_added_list', 'gen_success'));
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* deleteInstructorsFromPeriod(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.InstructorsDeleteFromPeriod(action.body));
    if (response && response.status === 200) {
      yield put(Actions.Notification('notification_selected_instructors_deleted', 'gen_success'));
      if (action.callback) {
        action.callback(response, response.status);
      }
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

export function* watchInstructorHoursGetById() {
  yield takeEvery(
    (action: any) => action.target === constants.EXAM_PERIOD_GET_INSTRUCTOR_HOURS,
    getInstructorHoursById
  );
}

export function* watchInstructorsAddToPeriod() {
  yield takeEvery(
    (action: any) => action.target === constants.EXAM_PERIOD_ADD_INSTRUCTORS_TO_PERIOD,
    addInstructorsToPeriod
  );
}

export function* watchInstructorSaveInvigilations() {
  yield takeEvery(
    (action: any) => action.target === constants.EXAM_PERIOD_SAVE_INSTRUCTOR_INVIGILATIONS,
    saveInstructorInvigilations
  );
}

export function* watchInstructorHoursUpdate() {
  yield takeEvery(
    (action: any) => action.target === constants.EXAM_PERIOD_UPDATE_INSTRUCTOR_HOURS,
    saveInstructorHours
  );
}

export function* watchInstructorHoursUpdateMultiple() {
  yield takeEvery(
    (action: any) => action.target === constants.EXAM_PERIOD_UPDATE_MULTIPLE_INSTRUCTOR_HOURS,
    saveInstructorHoursMultiple
  );
}

export function* watchInstructorsDeleteFromPeriod() {
  yield takeEvery(
    (action: any) => action.target === constants.EXAM_PERIOD_DELETE_INSTRUCTORS_FROM_PERIOD,
    deleteInstructorsFromPeriod
  );
}

//#endregion

//#region Classroom Hours Tab

function* getClassroomHoursById(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.ClassroomHoursGetById(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  if (action.callback && content) {
    action.callback(content);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* addClassroomsToPeriod(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.ClassroomsAddToPeriod(action.body));
    if (response && response.status === 200) {
      yield put(Actions.Notification('notification_selected_classrooms_added_list', 'gen_success'));
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* saveClassroomHours(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.ClassroomHoursUpdate(action.body));
    if (response && response.status === 200) {
      yield put(Actions.Notification('notification_programs_assigned_classroom_saved', 'gen_success'));
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* saveMultipleClassroomHours(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.ClassroomMultipleHoursUpdate(action.body));
    if (response && response.status === 200) {
      yield put(Actions.Notification('notification_selected_classrooms_assigned_hours_saved', 'gen_success'));
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* deleteClassroomsFromPeriod(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.ClassroomsDeleteFromPeriod(action.body));
    if (response && response.status === 200) {
      yield put(Actions.Notification('notification_selected_classrooms_deleted', 'gen_success'));
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

export function* watchClassroomHoursGetById() {
  yield takeEvery((action: any) => action.target === constants.EXAM_PERIOD_GET_CLASSROOM_HOURS, getClassroomHoursById);
}

export function* watchTermWeekDates() {
  yield takeEvery((action: any) => action.target === constants.COURSE_PERIOD_GET_TERM_WEEK_DATES, getTermWeekDates);
}

export function* watchPutTermWeekDates() {
  yield takeEvery((action: any) => action.target === constants.COURSE_PERIOD_PUT_TERM_WEEK_DATES, putTermWeekDates);
}

export function* watchTermWeekExclutionDatesDelete() {
  yield takeEvery((action: any) => action.target === constants.COURSE_PERIOD_DELETE_EXCLUTION, deleteTermWeekExclutionDate);
}

export function* watchTermWeekHolidayDatesDelete() {
  yield takeEvery((action: any) => action.target === constants.COURSE_PERIOD_DELETE_HOLIDAY, deleteTermWeekHolidayDate);
}

export function* watchClassroomsAddToPeriod() {
  yield takeEvery(
    (action: any) => action.target === constants.EXAM_PERIOD_ADD_CLASSROOMS_TO_PERIOD,
    addClassroomsToPeriod
  );
}

export function* watchClassroomHoursUpdate() {
  yield takeEvery((action: any) => action.target === constants.EXAM_PERIOD_UPDATE_CLASSROOM_HOURS, saveClassroomHours);
}

export function* watchMultipleClassroomHoursUpdate() {
  yield takeEvery(
    (action: any) => action.target === constants.EXAM_PERIOD_UPDATE_MULTIPLE_CLASSROOM_HOURS,
    saveMultipleClassroomHours
  );
}

export function* watchClassroomsDeleteFromPeriod() {
  yield takeEvery(
    (action: any) => action.target === constants.EXAM_PERIOD_DELETE_CLASSROOMS_FROM_PERIOD,
    deleteClassroomsFromPeriod
  );
}

//#endregion

//#region Course Hours Tab

function* getCourseHoursById(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.CourseHoursGetById(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  if (action.callback && content) {
    action.callback(content);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

//For Activities
function* getCourseTermCourseHoursById(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.CourseTermCourseHoursGetById(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  if (action.callback && content) {
    action.callback(content);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* addCoursesToPeriod(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.CoursesAddToPeriod(action.body));
    if (response && response.status === 200) {
      yield put(Actions.Notification('notification_selected_courses_added_list', 'gen_success'));
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* createCombinedExams(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.CreateCombinedExams(action.body));
    if (response && response.status === 200) {
      yield put(Actions.Notification('notification_selected_courses_exams_combined', 'gen_success'));
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* createCombinedActivities(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.CreateCombinedActivities(action.body));
    if (response && response.status === 200) {
      yield put(Actions.Notification('notification_selected_courses_exams_combined', 'gen_success'));
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* copyCourseToPeriod(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.CopyCourseToPeriod(action.body));
    if (response && response.status === 200) {
      yield put(Actions.Notification('notification_activity_copied', 'gen_success'));
    } else if (response && response.status === 400) {
      yield put(Actions.Notification('notification_course_code_must_be_uniqe', 'gen_warning', 'danger'));
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  if (response && action.callback) {
    action.callback(response.status);
  }
}
function* courseTermCopyCourseToPeriod(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.CourseTermCopyCourseToPeriod(action.body));
    if (response && response.status === 200) {
      yield put(Actions.Notification('notification_activity_copied', 'gen_success'));
    } else if (response && response.status === 400) {
      yield put(Actions.Notification('notification_course_code_must_be_uniqe', 'gen_warning', 'danger'));
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  if (response && action.callback) {
    action.callback(response.status);
  }
}
function* updateCombinedExams(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.UpdateCombinedExams(action.body));
    if (response && response.status === 200) {
      yield put(Actions.Notification('notification_selected_courses_exams_combined', 'gen_success'));
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* attachClassroomToCourses(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.AttachClassroomToCourses(action.body));
    if (response && response.status === 200) {
      yield put(Actions.Notification('notification_selected_classrooms_assigned_to_courses', 'gen_success'));
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* courseTermAttachClassroomToCourses(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.CourseTermAttachClassroomToCourses(action.body));
    if (response && response.status === 200) {
      yield put(Actions.Notification('notification_selected_classrooms_assigned_to_courses', 'gen_success'));
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getAttachedClassrooms(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.GetAttachedClassrooms(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getOverlaps(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.GetOverlaps(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* courseTermGetOverlaps(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.CourseTermGetOverlaps(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* deleteAttachedClassrooms(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.DeleteAttachedClassrooms(action.body));
    if (response && response.status === 200) {
      yield put(Actions.Notification('notification_selected_classrooms_deleted', 'gen_success'));
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* saveCourseHours(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.CourseHoursUpdate(action.body));
    if (response && response.status === 200) {
      yield put(Actions.Notification('notification_programs_assigned_course_saved', 'gen_success'));
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* editCourse(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.CourseHoursEdit(action.body));
    if (response && response.status === 200) {
      yield put(Actions.Notification('notification_course_updated', 'gen_success'));
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* courseTermEditCourse(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.CourseTermCourseHoursEdit(action.body));
    if (response && response.status === 200) {
      yield put(Actions.Notification('notification_course_updated', 'gen_success'));
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* saveMultipleCourseHours(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.MultipleCourseHoursUpdate(action.body));
    if (response && response.status === 200) {
      yield put(Actions.Notification('notification_selected_courses_assigned_hours_saved', 'gen_success'));
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* deleteCoursesFromPeriod(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.CoursesDeleteFromPeriod(action.body));
    if (response && response.status === 200) {
      yield put(Actions.Notification('notification_selected_courses_deleted', 'gen_success'));
      if (action.callback) {
        action.callback(response, response.status);
      }
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

export function* watchCourseHoursGetById() {
  yield takeEvery(
    (action: any) =>
      action.target === constants.EXAM_PERIOD_GET_EXAM_HOURS,
    getCourseHoursById
  );
}

export function* watchCourseTermCourseHoursGetById() {
  yield takeEvery(
    (action: any) =>
      //action.target === constants.EXAM_PERIOD_GET_EXAM_HOURS ||
      action.target === constants.EXAM_PERIOD_GET_COURSETERM_COURSE_HOURS,
    getCourseTermCourseHoursById
  );
}

// For Course Term
function* addActivitiesToPeriod(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.ActivitiesAddToPeriod(action.body));
    if (response && response.status === 200) {
      yield put(Actions.Notification('notification_selected_courses_added_list', 'gen_success'));
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

export function* watchActivitiesAddToPeriod() {
  yield takeEvery((action: any) => action.target === constants.EXAM_PERIOD_ADD_ACTIVITIES_TO_PERIOD, addActivitiesToPeriod);
}

export function* watchCoursesAddToPeriod() {
  yield takeEvery((action: any) => action.target === constants.EXAM_PERIOD_ADD_COURSES_TO_PERIOD, addCoursesToPeriod);
}

export function* watchCopyCourseToPeriod() {
  yield takeEvery((action: any) => action.target === constants.EXAM_PERIOD_COPY_COURSE_TO_PERIOD, copyCourseToPeriod);
}

export function* watchCourseTermCopyCourseToPeriod() {
  yield takeEvery((action: any) => action.target === constants.EXAM_PERIOD_COURSETERM_COPY_COURSE_TO_PERIOD, courseTermCopyCourseToPeriod);
}

export function* watchAttachClassroomToCourses() {
  yield takeEvery(
    (action: any) => action.target === constants.EXAM_PERIOD_ATTACH_CLASSROOMS_TO_COURSES,
    attachClassroomToCourses
  );
}

export function* watchCourseTermAttachClassroomToCourses() {
  yield takeEvery(
    (action: any) => action.target === constants.EXAM_PERIOD_COURSETERM_ATTACH_CLASSROOMS_TO_COURSES,
    courseTermAttachClassroomToCourses
  );
}

export function* watchGetAttachedClassrooms() {
  yield takeEvery(
    (action: any) => action.target === constants.EXAM_PERIOD_GET_ATTACHED_CLASSROOMS,
    getAttachedClassrooms
  );
}

export function* watchGetOverlaps() {
  yield takeEvery((action: any) => action.target === constants.EXAM_PERIOD_GET_OVERLAPS, getOverlaps);
}

export function* watchCourseTermGetOverlaps() {
  yield takeEvery((action: any) => action.target === constants.EXAM_PERIOD_COURSETERM_GET_OVERLAPS, courseTermGetOverlaps);
}

export function* watchDeleteAttachedClassrooms() {
  yield takeEvery(
    (action: any) => action.target === constants.EXAM_PERIOD_DELETE_ATTACHED_CLASSROOMS,
    deleteAttachedClassrooms
  );
}

export function* watchCreateCombinedExams() {
  yield takeEvery((action: any) => action.target === constants.EXAM_PERIOD_CREATE_COMBINE_EXAMS, createCombinedExams);
}

export function* watchCreateCombinedActivities() {
  yield takeEvery((action: any) => action.target === constants.EXAM_PERIOD_CREATE_COMBINE_ACTIVITES, createCombinedActivities);
}

export function* watchUpdateCombinedExams() {
  yield takeEvery((action: any) => action.target === constants.EXAM_PERIOD_UPDATE_COMBINE_EXAMS, updateCombinedExams);
}

export function* watchCourseHoursUpdate() {
  yield takeEvery((action: any) => action.target === constants.EXAM_PERIOD_UPDATE_COURSE_HOURS, saveCourseHours);
}

export function* watchCourseHoursEditCourse() {
  yield takeEvery((action: any) => action.target === constants.EXAM_PERIOD_EDIT_COURSE, editCourse);
}

export function* watchCourseTermCourseHoursEditCourse() {
  yield takeEvery((action: any) => action.target === constants.EXAM_PERIOD_COURSETERM_EDIT_COURSE, courseTermEditCourse);
}

export function* watchMultipleCourseHoursUpdate() {
  yield takeEvery(
    (action: any) => action.target === constants.EXAM_PERIOD_UPDATE_MULTIPLE_COURSE_HOURS,
    saveMultipleCourseHours
  );
}

export function* watchCoursesDeleteFromPeriod() {
  yield takeEvery(
    (action: any) => action.target === constants.EXAM_PERIOD_DELETE_COURSES_FROM_PERIOD,
    deleteCoursesFromPeriod
  );
}

//#endregion

function* getStudents(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.GetStudents(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* deleteStudentsFromPeriod(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.DeleteStudentsFromPeriod(action.body));
    if (response && response.status === 200) {
      yield put(Actions.Notification('notification_selected_students_deleted', 'gen_success'));
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* deleteAllStudentsFromPeriod(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.DeleteAllStudentsFromPeriod(action.body));
    if (response && response.status === 200) {
      yield put(Actions.Notification('notification_all_students_deleted', 'gen_success'));
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* createStudentBulk(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.StudentCreateBulk(action.body));
    if (response && (response.status === 200 || response.status === 409)) {
      content = yield MockAPI.GetContent(response);
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* checkStudents(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.StudentCheck(action.body));
    if (response && (response.status === 200 || response.status === 409)) {
      yield put(Actions.Notification('gen_students_check_completed', 'gen_success'));
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* updateCoursesViaStudents(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.UpdateCoursesViaStudents(action.body));
    if (response && (response.status === 200 || response.status === 409)) {
      yield put(Actions.Notification('gen_update_courses_completed', 'gen_success'));
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* studentsChangeAllData(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.StudentChangeAllData(action.body));
    if (response && (response.status === 200 || response.status === 409)) {
      yield put(Actions.Notification('notification_all_data_for_students_changed', 'gen_success'));
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getStudentGetCourseProgramsByFaculties(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.StudentGetCourseProgramsByFaculties(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getStudentGetEnrolledProgramsByFaculties(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.StudentGetEnrolledProgramsByFaculties(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getStudentSelectOptions(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.StudentGetSelectOptions());
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

export function* watchStudentsGet() {
  yield takeEvery((action: any) => action.target === constants.EXAM_PERIOD_GET_STUDENTS, getStudents);
}

export function* watchStudentsDelete() {
  yield takeEvery(
    (action: any) => action.target === constants.EXAM_PERIOD_DELETE_STUDENTS_FROM_PERIOD,
    deleteStudentsFromPeriod
  );
}

export function* watchStudentsDeleteAll() {
  yield takeEvery(
    (action: any) => action.target === constants.EXAM_PERIOD_DELETE_ALL_STUDENTS_FROM_PERIOD,
    deleteAllStudentsFromPeriod
  );
}

export function* watchCreateStudentBulk() {
  yield takeEvery((action: any) => action.target === constants.EXAM_PERIOD_STUDENTS_CREATE_BULK, createStudentBulk);
}

export function* watchCheckStudents() {
  yield takeEvery((action: any) => action.target === constants.EXAM_PERIOD_STUDENTS_CHECK, checkStudents);
}

export function* watchUpdateCoursesViaStudents() {
  yield takeEvery((action: any) => action.target === constants.EXAM_PERIOD_STUDENTS_UPDATE_COURSES, updateCoursesViaStudents);
}

export function* watchStudentsChangeAllData() {
  yield takeEvery((action: any) => action.target === constants.EXAM_PERIOD_STUDENTS_CHANGE_ALL_DATA, studentsChangeAllData);
}

export function* watchStudentGetCourseProgramsByFaculties() {
  yield takeEvery(
    (action: any) => action.target === constants.EXAM_PERIOD_STUDENTS_GET_COURSE_PROGRAMS_BY_FACULTIES,
    getStudentGetCourseProgramsByFaculties
  );
}

export function* watchStudentGetEnrolledProgramsByFaculties() {
  yield takeEvery(
    (action: any) => action.target === constants.EXAM_PERIOD_STUDENTS_GET_ENROLLED_PROGRAMS_BY_FACULTIES,
    getStudentGetEnrolledProgramsByFaculties
  );
}

export function* watchStudentGetSelectOptions() {
  yield takeEvery((action: any) => action.target === constants.EXAM_PERIOD_STUDENTS_GET_SELECT_OPTIONS, getStudentSelectOptions);
}

export function* watchGetExamPeriodList() {
  yield takeEvery((action: any) => action.target === constants.EXAM_PERIOD_GET_LIST, getExamPeriodList);
}

